import React, { useEffect, useState } from "react";
import { FaBars, FaClock, FaReceipt } from "react-icons/fa";
import { TfiMenuAlt } from "react-icons/tfi";
import { MdShoppingCart } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
// import { AiFillGolden } from "react-icons/ai";
import userImg from "../../Asset/user-img.png";
import backOffice from "../../Asset/back office.png";
import support from "../../Asset/support.png";
import { NavLink, useNavigate } from "react-router-dom";
import { BsCartCheckFill } from "react-icons/bs";
import { OnlyAdmin } from "../../Protect/HiddenLink";

const sidebarSections = [
  { name: "Orders", icon: <MdShoppingCart />, path: "/orderType" },
  { name: "Receipts", icon: <FaReceipt />, path: "/receipts" },
  {
    name: "Completing Order",
    icon: <BsCartCheckFill />,
    path: "/completingOrder",
  },
  // { name: "Shift", icon: <FaClock />, path: "/shift" },
  { name: "Items", icon: <TfiMenuAlt />, path: "/items" },

  { name: "Settings", icon: <IoIosSettings />, path: "/settings" },
];

const sidebarGuide = [
  { name: "Back office", image: backOffice },
  { name: "Support", image: support, version: "v 2.30.1" },
];

const SidebarNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => setIsOpen(!isOpen);

  const navigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  useEffect(() => {
    const handler = (event) => {
      if (!event.target.closest(".sidebar") && isOpen) setIsOpen(false);
    };
    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [isOpen]);

  return (
    <div className="relative">
      {/* Sidebar Toggle Button */}
      <FaBars
        onClick={toggleSidebar}
        className="text-white text-xl cursor-pointer"
      />

      {/* Sidebar and Overlay */}
      <div
        className={`${
          isOpen ? "fixed inset-0 z-50 bg-black bg-opacity-70" : ""
        }`}
        onClick={() => setIsOpen(false)}
      >
        <div
          className={`fixed z-10 top-0 left-0 h-full lg:w-[30%] w-[39%] overflow-y-auto scrollbar-hide bg-white text-[#555555] transform ${
            isOpen ? "translate-x-0" : "-translate-x-full hidden"
          } transition-transform duration-300 ease-in-out sidebar`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col min-h-screen justify-between gap-6">
            {/* Top Section */}
            <div>
              <div className="flex items-center bg-[#555555] text-white lg:py-7 py-4 lg:px-6 px-3 gap-4">
                <img src={userImg} alt="User" />
                <div>
                  <h1 className="lg:text-[1.5rem] text-[1rem] font-inter-700">
                    Business Name
                  </h1>
                  <p className="lg:text-[1rem] text-[.7rem] font-inter-400">
                    POS 1
                  </p>
                  <p className="lg:text-[1rem] text-[.7rem] font-inter-400">
                    User Name
                  </p>
                </div>
              </div>

              {/* Navigation Links */}
              {sidebarSections.map((section) =>
                section.name === "Items" || section.name === "Receipts" ? (
                  <OnlyAdmin key={section.path}>
                    <NavLink
                      to={section.path}
                      className={({ isActive }) =>
                        `px-6 lg:px-8 lg:py-4 py-3 border-b border-[#DEDEDE] cursor-pointer lg:text-[1rem] text-[.8rem] font-inter-600 flex items-center hover:text-white hover:bg-[#0D868C] ${
                          isActive
                            ? "text-white bg-[#0D868C]"
                            : "bg-white text-[#A9A9A9]"
                        }`
                      }
                      onClick={() => navigation(section.path)}
                    >
                      <div className="flex items-center">
                        <span className="mr-4 text-[1.3rem]">
                          {section.icon}
                        </span>
                        <span>{section.name}</span>
                      </div>
                    </NavLink>
                  </OnlyAdmin>
                ) : (
                  <NavLink
                    key={section.path}
                    to={section.path}
                    className={({ isActive }) =>
                      `px-6 lg:px-8 lg:py-4 py-3 border-b border-[#DEDEDE] cursor-pointer lg:text-[1rem] text-[.8rem] font-inter-600 flex items-center hover:text-white hover:bg-[#0D868C] ${
                        isActive
                          ? "text-white bg-[#0D868C]"
                          : "bg-white text-[#A9A9A9]"
                      }`
                    }
                    onClick={() => navigation(section.path)}
                  >
                    <div className="flex items-center">
                      <span className="mr-4 text-[1.3rem]">{section.icon}</span>
                      <span>{section.name}</span>
                    </div>
                  </NavLink>
                )
              )}
            </div>

            {/* Lower Section */}
            <div>
              {sidebarGuide.map((guide, i) => (
                <div
                  key={i}
                  className="px-6 lg:px-8 lg:py-3 py-2 cursor-pointer lg:text-[1rem] text-[.8rem] font-inter-600 flex items-center bg-white text-[#A9A9A9]"
                >
                  <img src={guide.image} alt={guide.name} className="mr-4" />
                  <div className="flex justify-between w-full items-center">
                    <span className="text-[#0D868C]">{guide.name}</span>
                    <span className="lg:text-[12px] text-[10px] font-inter-600">
                      {guide.version}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarNav;
