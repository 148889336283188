import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/features/auth/AuthSlice";

const UserForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  console.log(errors);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setValue("profile", file);
    }
  };

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    console.log("Form Data:", data);

    const createUserData = new FormData();
    createUserData.append("name", data.fullName);
    createUserData.append("email", data.email);
    createUserData.append("password", data.password);

    if (data.profile) {
      createUserData.append("profileImage", data.profile);
    }

    // Log FormData values
    for (let [key, value] of createUserData.entries()) {
      console.log(`${key}:`, value);
    }

    await dispatch(registerUser(createUserData));
  };

  return (
    <div className="w-full mt-8">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" lg:w-[35rem] mx-auto flex flex-col"
      >
        {/* Full Name */}
        <div className="mb-4 flex items-center gap-4">
          <label className="block lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black mb-2 w-[30%] text-end">
            Full Name:
          </label>
          <input
            type="text"
            placeholder="Enter full name"
            className="w-[70%] px-3 h-[50px] border-2 bg-transparent border-[#D4D4D4] rounded-[10px]"
            {...register("fullName", { required: true })}
          />
        </div>

        {/* Email */}
        <div className="mb-4 flex items-center gap-4">
          <label className="block lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black mb-2 w-[30%] text-end">
            Email:
          </label>
          <input
            type="email"
            placeholder="Enter email"
            className="w-[70%] px-3 h-[50px] border-2 bg-transparent border-[#D4D4D4] rounded-[10px]"
            {...register("email", { required: true })}
          />
        </div>

        {/* Password */}
        <div className="mb-4 flex items-center gap-4">
          <label className="block lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black mb-2 w-[30%] text-end">
            Password:
          </label>
          <input
            type="password"
            placeholder="Enter password"
            className="w-[70%] px-3 h-[50px] border-2 bg-transparent border-[#D4D4D4] rounded-[10px]"
            {...register("password", { required: true })}
          />
        </div>

        {/* Image Upload with Preview */}
        <div className="mb-4 flex items-start gap-4">
          <label className="block lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black mb-2 w-[30%] text-end">
            Image:
          </label>
          <div className="w-[70%] bg-transparent h-44 border-2 border-[#D4D4D4] rounded-[10px] flex items-center justify-center relative">
            <input
              type="file"
              id="imageUpload"
              className="hidden"
              accept="image/*"
              onChange={handleImageChange}
            />
            <label
              htmlFor="imageUpload"
              className="text-gray-400 cursor-pointer"
            >
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-60 h-40 px-4 "
                />
              ) : (
                "Upload Image"
              )}
            </label>
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-[#0D868C] text-white px-6 py-2 mt-8 rounded-full w-full"
        >
          Add User
        </button>
      </form>
    </div>
  );
};

export default UserForm;
