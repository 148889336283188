import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../component/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  getOrdersByDateTimeRange,
  RESETORDER,
  selectOrderByDate,
  selectOrders,
} from "../../redux/features/order/OrderSlice";
import dayjs from "dayjs";
import { GiMoneyStack } from "react-icons/gi";
import {
  filterReceipts,
  selectFilteredReceipts,
} from "../../redux/features/filter/FilterSlice";
import { FaDownload, FaPaperPlane } from "react-icons/fa";
import { toast } from "react-toastify";
// import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import logo from "../../Asset/logo.png";

const Receipt = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const [groupedOrders, setGroupedOrders] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [visibleDates, setVisibleDates] = useState({});
  const [search, setSearch] = useState("");
  const filterReceipt = useSelector(selectFilteredReceipts);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const managerName = process.env.REACT_APP_MANAGER_NAME;
  const companyAddress = process.env.REACT_APP_COMPANY_ADDRESS;
  const companyPhone = process.env.REACT_APP_COMPANY_PHONE;
  const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;
  const companyWebsite = process.env.REACT_APP_COMPANY_WEBSITE;

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const orderByDate = useSelector(selectOrderByDate);
  // console.log(orderByDate);
  const [dineInTakeAwayOrders, setDineInTakeAwayOrders] = useState([]);
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [cardOrders, setCardOrders] = useState([]);
  const [cashOrders, setCashOrders] = useState([]);
  const filterOrders = (orders, orderType) => {
    return orders.filter((order) => order.orderType === orderType);
  };
  const filterOrdersByPayment = (orders, amountType) => {
    return orders.filter(
      (order) => order.amountType.toLowerCase() === amountType
    );
  };

  useEffect(() => {
    setDineInTakeAwayOrders(filterOrders(orderByDate, "Dine In / Take Away"));
    setDeliveryOrders(filterOrders(orderByDate, "Delivery"));
    setCardOrders(filterOrdersByPayment(orderByDate, "card"));
    setCashOrders(filterOrdersByPayment(orderByDate, "cash"));
  }, [orderByDate]);

  const handleClick = async () => {
    if (!dates.startDate || !dates.endDate) {
      toast.error("Start and End Date both are requried");
      return;
    }
    const orderData = {
      startDate: dates.startDate,
      endDate: dates.endDate,
    };
    await dispatch(getOrdersByDateTimeRange(orderData));
  };

  const toggleDateVisibility = (date) => {
    setVisibleDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  // set the date format and last 30 days receipt
  useEffect(() => {
    if (filterReceipt && filterReceipt.length > 0) {
      const now = dayjs();
      const filteredOrders = filterReceipt.filter((order) =>
        dayjs(order.createdAt).isAfter(now.subtract(30, "days"))
      );

      const grouped = filteredOrders.reduce((acc, order) => {
        const orderDate = dayjs(order.createdAt).format("D MMMM YYYY (dddd)");
        if (!acc[orderDate]) {
          acc[orderDate] = [];
        }
        acc[orderDate].push(order);
        return acc;
      }, {});

      setGroupedOrders(grouped);
    }
  }, [filterReceipt]);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    dispatch(RESETORDER());
    setDates({
      startDate: "",
      endDate: "",
    });
  };

  useEffect(() => {
    dispatch(filterReceipts({ search, orders }));
    console.log(dates.startDate);
  }, [dispatch, search, orders, dates]);
  // const contentRef = useRef(null);

  // const printWindow = () => {
  //   // Check if contentRef is not null
  //   if (contentRef.current) {
  //     const printWindow = window.open("", "", "width=800,height=600");
  //     printWindow.document.write("<html><body>");
  //     // Access content only if contentRef is valid
  //     printWindow.document.write(contentRef.current.innerHTML);
  //     printWindow.document.write("</body></html>");
  //     printWindow.document.close();
  //     printWindow.print();
  //   } else {
  //     console.error("Content not available for printing.");
  //   }
  // };

  // const conponentPDF = useRef();

  // const generatePDF = useReactToPrint({
  //   content: () => conponentPDF.current,
  //   documentTitle: "Userdata",
  //   onAfterPrint: () => alert("Data saved in PDF"),
  // });

  const componentPDF = useRef();
  const generatePDF = () => {
    const element = componentPDF.current;
    const options = {
      margin: 0.5,
      filename: "Orders Summary.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  const totalAmount = orderByDate
    .reduce((sum, order) => {
      const amountToAdd =
        order.totalAmountAfterDiscount > 0
          ? order.totalAmountAfterDiscount
          : order.totalAmount;
      return sum + amountToAdd;
    }, 0)
    .toFixed(2);

  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      <div>
        <Navbar page="Receipts" />
      </div>
      <div className="flex h-full overflow-hidden">
        <div className="lg:w-[30%] md:w-[39%] md:block hidden overflow-y-auto scrollbar-hide bg-white shadow-md">
          {/* <OrderList /> */}
          <div className="w-full">
            <input
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="outline-none p-4 w-full"
              placeholder="Search by order no"
            />
          </div>
          <div>
            {Object.entries(groupedOrders).map(([date, orders]) => (
              <div key={date}>
                <div
                  onClick={() => toggleDateVisibility(date)}
                  className={`px-6 lg:px-8 bg-[#7D7D7D] text-white py-3 text-[11px] lg:text-[13px] font-bold cursor-pointer ${
                    visibleDates[date] ? "" : "border-b border-white"
                  }`}
                >
                  {date}
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ${
                    visibleDates[date] ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  {orders.map((order) => (
                    <div
                      key={order._id}
                      onClick={() => handleOrderClick(order)}
                      className={`border-b px-6 lg:px-8 py-2 flex items-center cursor-pointer ${
                        selectedOrder && selectedOrder._id === order._id
                          ? "bg-[#0D868C] "
                          : ""
                      }`}
                    >
                      <GiMoneyStack
                        className={`size-8 mr-3  ${
                          selectedOrder && selectedOrder._id === order._id
                            ? "text-white "
                            : "text-[#0D868C]"
                        }`}
                      />{" "}
                      <div className="order-info">
                        <div
                          className={`lg:text-[1rem] text-[.8rem] font-semibold ${
                            selectedOrder && selectedOrder._id === order._id
                              ? "text-white "
                              : "text-[#1E1E1E]"
                          }`}
                        >
                          €
                          {order.totalAmountDiscount > 0
                            ? order.totalAmountAfterDiscount.toFixed(2)
                            : order.totalAmount.toFixed(2)}
                        </div>
                        <div
                          className={`lg:text-[.75rem] text-[.6rem] font-semibold ${
                            selectedOrder && selectedOrder._id === order._id
                              ? "text-[white] "
                              : "text-[#A9A9A9]"
                          }`}
                        >
                          {dayjs(order.createdAt).format("h:mm A")}
                        </div>
                      </div>
                      <div
                        className={`ml-auto text-[.75rem] ${
                          selectedOrder && selectedOrder._id === order._id
                            ? "text-[white] "
                            : "text-[#A9A9A9]"
                        }`}
                      >
                        #{order.orderNo}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* card */}
        <div className="flex-1 lg:p-8 py-6 overflow-y-auto scrollbar-hide relative flex flex-col justify-between">
          {orderByDate?.length > 0 ? (
            <div className="flex flex-col justify-between gap-3 px-10">
              <div className="flex justify-end">
                <button
                  onClick={generatePDF}
                  className="flex justify-center w-max items-center text-[white] bg-[#0D868C] rounded-lg p-2 font-semibold gap-2"
                >
                  {" "}
                  <FaDownload />
                  DownLoad
                </button>
              </div>

              <div
                ref={componentPDF}
                style={{ width: "100%" }}
                className=" lg:py-6 py-4 px-4 w-[80%] mx-auto bg-white shadow-md
                rounded-xl flex flex-col gap-4"
              >
                <div className="text-[#888787] flex flex-col">
                  <div className="flex justify-between">
                    {companyName && (
                      <p className="text-2xl text-[#575656] font-extrabold">
                        {companyName}
                      </p>
                    )}
                    <img src={logo} className="w-14 ml-auto" alt="" />
                  </div>
                  {companyEmail && (
                    <p>
                      Email:{" "}
                      <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
                    </p>
                  )}
                  {companyWebsite && (
                    <p>
                      Website:{" "}
                      <a
                        href={companyWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {companyWebsite}
                      </a>
                    </p>
                  )}
                  {companyAddress && <p>Address: {companyAddress}</p>}
                  {companyPhone && <p>Phone: {companyPhone}</p>}
                  {managerName && <p>Manager: {managerName}</p>}
                  {totalAmount && <p>Total Sale : €{totalAmount} </p>}
                  <p>
                    Start Date:{" "}
                    {dayjs(dates.startDate).format("YYYY-MM-DD hh:mm A")}
                  </p>
                  <p>
                    End Date:{" "}
                    {dayjs(dates.endDate).format("YYYY-MM-DD hh:mm A")}
                  </p>
                </div>
                {/* report table */}
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-4 py-2 text-left">
                        Order Type
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-left">
                        Total Orders
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-left">
                        Total Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2">
                        Total Order
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {orderByDate.length}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {orderByDate
                          .reduce((sum, order) => {
                            const amountToAdd =
                              order.totalAmountAfterDiscount > 0
                                ? order.totalAmountAfterDiscount
                                : order.totalAmount;
                            return sum + amountToAdd;
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2">
                        Delivery Order
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {deliveryOrders.length}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {deliveryOrders.reduce((sum, order) => {
                          const amountToAdd =
                            order.totalAmountAfterDiscount > 0
                              ? order.totalAmountAfterDiscount
                              : order.totalAmount;
                          return sum + amountToAdd;
                        }, 0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2">
                        Dine In / Take Away Order
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {dineInTakeAwayOrders.length}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {dineInTakeAwayOrders
                          .reduce((sum, order) => {
                            const amountToAdd =
                              order.totalAmountAfterDiscount > 0
                                ? order.totalAmountAfterDiscount
                                : order.totalAmount;
                            return sum + amountToAdd;
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2">Cash</td>
                      <td className="border border-gray-300 px-4 py-2">
                        {cashOrders.length}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {cashOrders
                          .reduce((sum, order) => {
                            const amountToAdd =
                              order.totalAmountAfterDiscount > 0
                                ? order.totalAmountAfterDiscount
                                : order.totalAmount;
                            return sum + amountToAdd;
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2">Card</td>
                      <td className="border border-gray-300 px-4 py-2">
                        {cardOrders.length}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {cardOrders
                          .reduce((sum, order) => {
                            const amountToAdd =
                              order.totalAmountAfterDiscount > 0
                                ? order.totalAmountAfterDiscount
                                : order.totalAmount;
                            return sum + amountToAdd;
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* {orderByDate.map((order, i) => (
                  <div key={i} className="text-center border-y page-content">
                    <p>Order No: {order.orderNo}</p>
                    <p>User: {order.username}</p>
                    <p>Order Type: {order.orderType}</p>
                    <p>Total Amount: €{order.totalAmount.toFixed(2)}</p>
                    <p>Status: {order.orderStatus}</p>
                    <p>
                      Created At: {new Date(order.createdAt).toLocaleString()}
                    </p>

                    {order.products.map((product, j) => (
                      <div key={j}>
                        <p>Product Name: {product.name}</p>
                        <p>Quantity: {product.quantity}</p>
                        <p>Price: €{product.price}</p>
                      </div>
                    ))}
                  </div>
                ))} */}
              </div>
            </div>
          ) : selectedOrder ? (
            <div>
              <div className="flex justify-center items-center">
                <div className="lg:py-6 py-4 px-4 w-[70%] lg:w-[50%] mx-auto bg-white shadow-md rounded-xl flex flex-col gap-4">
                  {/* Top Section */}
                  <div className="w-full text-center">
                    <h1 className="lg:text-[2.75rem] text-[2rem] font-bold text-[#1E1E1E]">
                      €{" "}
                      {selectedOrder.totalAmountDiscount > 0
                        ? selectedOrder.totalAmountAfterDiscount.toFixed(2)
                        : selectedOrder.totalAmount.toFixed(2)}
                    </h1>
                    <h2 className="text-[#0D868C] lg:text-[1rem] text-[.8rem] font-bold capitalize">
                      Total paid
                    </h2>
                  </div>
                  {/* customer section */}
                  <div className="flex justify-between items-center pt-2 lg:px-4 px-3">
                    <p className="text-[#1E1E1E] lg:text-[12px] text-[10px] font-normal flex flex-col">
                      {selectedOrder.customerName && (
                        <span className="capitalize">
                          Name: {selectedOrder.customerName}
                        </span>
                      )}
                      {selectedOrder.customerNumber && (
                        <span className="capitalize">
                          ph#: {selectedOrder.customerNumber}
                        </span>
                      )}
                      {selectedOrder.customerAddress && (
                        <span className="capitalize">
                          Address: {selectedOrder.customerAddress}
                        </span>
                      )}
                      {selectedOrder.AdditionalNote && (
                        <span className="capitalize">
                          Notes: {selectedOrder.AdditionalNote}
                        </span>
                      )}
                      {selectedOrder.deliverAt && (
                        <span className="capitalize">
                          deliver at: {selectedOrder.deliverAt}
                        </span>
                      )}
                    </p>
                  </div>
                  {/* Lower Top Section */}
                  <div className="flex justify-between items-center border-y border-[#0D868C] lg:p-4 p-3">
                    <p className="text-[#1E1E1E] lg:text-[12px] text-[10px] font-normal">
                      Employee:{" "}
                      <span className="capitalize">
                        {selectedOrder.username}
                      </span>
                    </p>
                    <p className="text-[#1E1E1E] lg:text-[14px] text-[12px] font-normal">
                      {selectedOrder.orderType}
                    </p>
                  </div>
                  {/* Middle Section */}
                  <div>
                    {selectedOrder.products.map((item, index) => (
                      <div
                        key={item._id}
                        className={`flex items-center w-full lg:gap-6 gap-4 p-2 ${
                          index !== 0 ? "border-t border-[#DEDEDE]" : ""
                        }`}
                      >
                        <div className="flex flex-col flex-1">
                          <h2 className="text-[#0D868C] text-[.8rem] lg:text-[1rem] font-semibold capitalize">
                            {item.name}{" "}
                            {item.size && (
                              <span className="text-[.6rem]">
                                ({item.size})
                              </span>
                            )}
                          </h2>
                          <h2>
                            {item.dealItems &&
                              item.dealItems?.map((item, i) => (
                                <>
                                  <h2 className="text-[#47b9be] text-[.7rem] font-semibold capitalize">
                                    {item.itemName}
                                  </h2>
                                  <div className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9]">
                                    {item.base && (
                                      <span>Base : {item.base} </span>
                                    )}
                                    {item.sauce && (
                                      <span>,Sauce : {item.sauce}</span>
                                    )}
                                    {item.cheese && (
                                      <span>,Cheese : {item.cheese}</span>
                                    )}
                                    {item.toppings?.length > 0 && (
                                      <span>
                                        ,Toppings :{" "}
                                        {item.toppings?.map((t, i) => (
                                          <span key={i}>
                                            {t}{" "}
                                            {i < item.toppings?.length - 1
                                              ? ", "
                                              : ""}{" "}
                                          </span>
                                        ))}
                                      </span>
                                    )}
                                  </div>
                                </>
                              ))}
                          </h2>
                          <h2 className="text-[#47b9be] text-[.7rem] font-semibold capitalize">
                            {item.add_ons.length > 0 &&
                              item.add_ons?.map((add_onsItem, i) => (
                                <div>{add_onsItem.name}</div>
                              ))}
                          </h2>
                          <div className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9]">
                            {item.base && <span>Base: {item.base} </span>}
                            {item.sauce && <span>, Sauce: {item.sauce}</span>}
                            {item.cheese && (
                              <span>, Cheese: {item.cheese}</span>
                            )}
                            {item.toppings?.length > 0 && (
                              <span>
                                Toppings:{" "}
                                {item.toppings.map((topping, i) => (
                                  <span key={i}>
                                    {topping}
                                    {i < item.toppings.length - 1 ? ", " : ""}
                                  </span>
                                ))}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center justify-between w-[25%] text-[#A9A9A9] text-[.8rem] lg:text-[1rem]">
                          <p className="w-[50%]">X{item.quantity}</p>
                          <p>
                            {item.discount > 0 ? (
                              <span>
                                €
                                {/* {(
                                Number(item.discountPrice) * item.quantity +
                                (item.toppingPrice?.length > 0
                                  ? item.toppingPrice.reduce(
                                      (acc, price) => acc + Number(price),
                                      0
                                    ) * item.quantity
                                  : 0)
                              ).toFixed(2)} */}
                                {(
                                  Number(item.discountPrice) * item.quantity +
                                  (item.add_ons.length > 0
                                    ? item.add_ons.reduce(
                                        (priceAcc, addOn) =>
                                          priceAcc +
                                          Number(addOn.price) * item.quantity,
                                        0
                                      )
                                    : 0) +
                                  (item.dealItems?.length > 0
                                    ? item.dealItems.reduce(
                                        (dealAcc, dealItem) => {
                                          const toppingPrice = Array.isArray(
                                            dealItem.toppingPrice
                                          )
                                            ? dealItem.toppingPrice.reduce(
                                                (priceAcc, price) =>
                                                  priceAcc + Number(price),
                                                0
                                              )
                                            : 0;
                                          return (
                                            dealAcc +
                                            toppingPrice * item.quantity
                                          );
                                        },
                                        0
                                      )
                                    : Array.isArray(item.toppingPrice) &&
                                      item.toppingPrice.length > 0
                                    ? item.toppingPrice.reduce(
                                        (acc, price) => acc + Number(price),
                                        0
                                      ) * item.quantity
                                    : 0)
                                ).toFixed(2)}
                              </span>
                            ) : (
                              <span>
                                €
                                {/* {(
                                Number(item.price) * item.quantity +
                                (item.toppingPrice?.length > 0
                                  ? item.toppingPrice.reduce(
                                      (acc, price) => acc + Number(price),
                                      0
                                    ) * item.quantity
                                  : 0)
                              ).toFixed(2)} */}
                                {(
                                  Number(item.price) * item.quantity +
                                  (item.add_ons.length > 0
                                    ? item.add_ons.reduce(
                                        (priceAcc, addOn) =>
                                          priceAcc +
                                          Number(addOn.price) * item.quantity,
                                        0
                                      )
                                    : 0) +
                                  (item.dealItems?.length > 0
                                    ? item.dealItems.reduce(
                                        (dealAcc, dealItem) => {
                                          // Calculate the topping price for each deal item
                                          const toppingPrice = Array.isArray(
                                            dealItem.toppingPrice
                                          )
                                            ? dealItem.toppingPrice.reduce(
                                                (priceAcc, price) =>
                                                  priceAcc + Number(price),
                                                0
                                              )
                                            : 0;
                                          return (
                                            dealAcc +
                                            toppingPrice * item.quantity
                                          );
                                        },
                                        0
                                      )
                                    : Array.isArray(item.toppingPrice) &&
                                      item.toppingPrice.length > 0
                                    ? item.toppingPrice.reduce(
                                        (acc, price) => acc + Number(price),
                                        0
                                      ) * item.quantity
                                    : 0)
                                ).toFixed(2)}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Upper Lower Section */}
                  <div>
                    <p className="flex flex-col justify-between items-center lg:p-4 p-3 lg:text-[1rem] text-[.8rem] border-y-2 border-[#DEDEDE]">
                      {selectedOrder.orderType.toLowerCase() === "delivery" && (
                        <div className="flex justify-between items-center w-full text-[.8rem]">
                          <span className="text-[#0D868C] font-bold">
                            Delivery Charges
                          </span>
                          <span className="text-[#868585]">
                            {selectedOrder.deliveryCharges > 0
                              ? ` € ${selectedOrder.deliveryCharges.toFixed(2)}`
                              : "Free"}
                          </span>
                        </div>
                      )}
                      {selectedOrder.totalAmountDiscount > 0 ? (
                        <div className="flex justify-between items-center w-full">
                          <span className="text-[#0D868C] font-bold">
                            Total
                          </span>
                          <span className="text-[#868585] flex gap-3 items-center ">
                            <span className=" line-through text-[#c2c0c0]">
                              €{selectedOrder.totalAmount.toFixed(2)}
                            </span>
                            €{selectedOrder.totalAmountAfterDiscount.toFixed(2)}
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-between items-center w-full">
                          <span className="text-[#0D868C] font-bold">
                            Total
                          </span>
                          <span className="text-[#868585]">
                            €{selectedOrder.totalAmount.toFixed(2)}
                          </span>
                        </div>
                      )}
                      {selectedOrder.charged > 0 && (
                        <div className="flex flex-col w-full text-[.8rem]">
                          <div className="flex justify-between items-center w-full">
                            <span className="text-[#0D868C] font-bold capitalize">
                              {selectedOrder.amountType}
                            </span>
                            <span className="text-[#868585]">
                              €{selectedOrder.charged.toFixed(2)}
                            </span>
                          </div>
                          {selectedOrder.totalAmountDiscount > 0 ? (
                            <div className="flex justify-between items-center w-full">
                              <span className="text-[#0D868C] font-bold">
                                Paid
                              </span>
                              <span className="text-[#868585] flex gap-3 items-center ">
                                €
                                {selectedOrder.totalAmountAfterDiscount.toFixed(
                                  2
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="flex justify-between items-center w-full">
                              <span className="text-[#0D868C] font-bold">
                                Paid
                              </span>
                              <span className="text-[#868585]">
                                €{selectedOrder.totalAmount.toFixed(2)}
                              </span>
                            </div>
                          )}
                          {selectedOrder.amountType.toLowerCase() ===
                            "cash" && (
                            <div className="flex justify-between items-center w-full">
                              <span className="text-[#0D868C] font-bold">
                                Change
                              </span>
                              <span className="text-[#868585]">
                                €{selectedOrder.returnAmount.toFixed(2)}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                  {/* Lower Section */}
                  <div className="flex justify-between items-center text-[#A9A9A9] lg:text-[12px] text-[10px] lg:px-4 px-3 py-2">
                    <p>
                      {dayjs(selectedOrder.createdAt).format(
                        "D /M /YYYY . h:mm A"
                      )}
                    </p>
                    <p>#{selectedOrder.orderNo}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-[#A9A9A9] pl-4">
                Click on an order to view details.
              </p>
            </div>
          )}

          {/* Date Input Container */}
          <div className="lg:w-[80%] w-full mx-auto sticky bottom-0 left-0 px-4 flex justify-center mt-8">
            <div className="bg-white/10 backdrop-blur px-4 py-2 pb-[.9rem] w-full  rounded-full shadow-md flex gap-2 items-end ">
              <div className="w-full flex flex-col justify-center">
                <label
                  htmlFor=""
                  className="text-[12px] pl-4 capitalize text-[#0D868C]"
                >
                  start date
                </label>

                <input
                  type="datetime-local"
                  name="startDate"
                  value={dates.startDate}
                  onChange={handleDateChange}
                  className="w-full  p-2 bg-white/10 backdrop-blur-0 lg:text-[12px] text-[10px] text-[#0D868C] border rounded-full outline-none "
                />
              </div>
              <div className="w-full flex flex-col justify-center">
                <label
                  htmlFor=""
                  className="text-[12px] pl-4 capitalize text-[#0D868C]"
                >
                  end date
                </label>
                <input
                  type="datetime-local"
                  name="endDate"
                  value={dates.endDate}
                  onChange={handleDateChange}
                  className="w-full p-2 bg-white/10 backdrop-blur-0 lg:text-[12px] text-[10px] text-[#0D868C] border rounded-full outline-none "
                />
              </div>
              <button
                onClick={handleClick}
                className="bg-[#0D868C]  rounded-full lg:p-3 p-[10px] text-white"
              >
                <FaPaperPlane className="lg:size-6 size-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
